<template>
  <div class="login">
    <!-- ref="ruleForm" 表单验证-->
    <el-form :model="form" :rules="rules" ref="ruleForm">
      <div class="title">甘谷县物流管理系统</div>
      <div class="main">
        <el-form-item prop="phone">
          <el-input prefix-icon="el-icon-user-solid" v-model="form.phone" placeholder="请输入手机号"></el-input>
        </el-form-item>
        <el-form-item prop="code">
          <div class="verify-wrapper">
            <el-input prefix-icon="el-icon-lock" placeholder="请输入验证码" v-model="form.code"></el-input>
            <el-button @click="getCode" class="getCode" :disabled="disabled">{{ btnText }}</el-button>
          </div>
        </el-form-item>
        <el-button @click="login" @keyup.enter="login" class="login">立即登录</el-button>
      </div>
    </el-form>
  </div>
</template>

<script>
export default {
  props: {},
  data() {
    return {
      form: {
        phone: "", //手机号
        code: "" //验证码
      },
      btnText: "获取验证码",
      disabled: false,
      rules: {
        phone: [
          { required: true, message: "请输入手机号", trigger: "blur" },
          {
            required: true,
            pattern: /^1[3456789]\d{9}$/,
            message: "请输入合法手机号",
            trigger: "blur"
          }
        ],
        code: [
          { required: true, message: "请输入手机号验证码", trigger: "blur" }
        ]
      }
    };
  },
  created() {
    document.onkeyup = e => {
      if (e.code == "Enter" || e.code == "enter") {
        this.login(); //调用登录 验证方法
      }
    };
  },
  methods: {
    getCode() {
      // 如果没有输入手机号，则不往下执行
      if (this.form.phone == "") {
        return;
      }
      this.axios
        .post("/api/site/login/sms/send", {
          phone: this.form.phone
        })
        .then(res => {
          console.log(res);
          if (res.data.success == false) {
            return this.$message.error("用户不存在，请联系管理员");
          }
          if (res.status !== 200) {
            return this.$message.error("验证码发送失败！");
          } else {
            let time = 60;
            this.btnText = time + "s后获取";
            this.disabled = false;
            let timer = setInterval(() => {
              if (time > 0) {
                --time;
                this.btnText = time + "s后获取";
                this.disabled = true;
              } else {
                this.btnText = "重新获取验证码";
                this.disabled = false;
                clearInterval(timer);
              }
            }, 1000);
          }
        });
    },

    login() {
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          this.axios
            .post("/api/site/login/sms/login", {
              phone: this.form.phone,
              code: this.form.code
            })
            .then(res => {
              //   console.log(res)
              //   if(res.data.data.roleFlag)
              if (res.data.code == 0) {
                this.$message({
                  message: "登陆成功",
                  type: "success",
                  duration: 1000, //弹框持续的时长
                  onClose: () => {
                    // 登陆成功以后，保存token
                    window.sessionStorage.setItem(
                      "token",
                      res.data.data.satoken
                    );

                    //实现页面跳转
                    this.$router.push("/home");
                    // console.log(res);
                  }
                });
              } else {
                this.$message({
                  message: res.data.message,
                  type: "error"
                });
              }
            });
        }
      });
    }
  }
};
</script>
<style lang="less" scoped>
.login {
  width: 100%;
  height: 100%;
  background: url(../assets/bg1.png);
  display: flex;
  justify-content: center;
  align-items: center;
}

.el-form {
  width: 518px;
  height: 398px;
  box-sizing: border-box;
  background: rgba(10, 27, 68, 0.92);
  border-radius: 8px;
  opacity: 0.95;

  .main {
    padding: 47px 106px;
  }

  .title {
    text-align: center;
    margin-top: 50px;
    width: 100%;
    height: 40px;
    font-size: 26px;
    color: #ffffff;
  }

  .el-form-item {
    width: 100%;
  }

  .verify-wrapper {
    position: relative;

    .getCode {
      position: absolute;
      top: 0;
      right: 0;
      height: 46px;
      text-align: center;
      background: rgba(216, 216, 216, 0);
      color: #4ba2ff;
      border: none;
    }
  }

  /deep/ .el-input__icon {
    font-size: 20px;
  }

  /deep/ .el-input__inner {
    height: 46px;
    font-size: 16px;
    background: rgba(216, 216, 216, 0);
    border-radius: 8px;
    border: 1px solid rgba(255, 255, 255, 0.8);
    color: #ffffff;
  }

  .login {
    margin-top: 43px;
    width: 100%;
    height: 46px;
    background: #027dff;
    font-size: 16px;
    color: #ffffff;
    border: none;
    border-radius: 8px;
  }
}
</style>
